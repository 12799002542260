<template>
  <v-card>
    <v-card-title>
      <div v-if="this.id">Свойства профессии</div>
      <div v-else>Добавление новой профессии</div>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>


      <v-text-field label="Наименование профессии" filled rounded v-model="profession.name"></v-text-field>
      <!--<v-text-field label="Требуемое образование" filled rounded-->
      <!--v-model="profession.reqEducation.educationLevel"></v-text-field>-->
      <v-select label="Уровень образования" filled rounded
                :items="Object.values(EducationLevels)"
                v-model="profession.educationLevel"></v-select>
      <v-text-field label="Квалификация" filled rounded
                    v-model="profession.reqEducation.qualification"></v-text-field>
      <v-text-field label="Специальность по диплому" filled rounded
                    v-model="profession.reqEducation.diplomaSpeciality"></v-text-field>

      <div class="skills-list-dialog">
        <h2>Справочник компетенций</h2>
        <div>
          <div v-for="(skill, skillIndex) in professionSkills" :key="skillIndex">
            <div>{{ skill.name }}</div>
            <div>
              <v-select filled rounded
                        :items="Object.values(marks)"
                        v-model="professionSkills[skillIndex].mark"
                        @change="updateSkills"
                        label="Выберите уровень">
              </v-select>
            </div>
          </div>
        </div>
      </div>

      <div
          v-for="(skill, skillIndex) in professionSkills"
          :key="skillIndex">
        <div class="badge" v-if="professionSkills[skillIndex].mark">
          <span>{{ skill.name }}</span>
          <span>Уровень {{ skill.mark }}</span>
        </div>
      </div>

    </v-card-text>
    <v-card-actions>
      <v-btn
          @click="cancel()"
      >
        Отмена
      </v-btn>
      <v-btn
          @click="save()"
          color="blue"
      >
        Сохранить изменения
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'AddProfDialog',
  components: {},
  methods: {
    cancel() {
      this.profession = {
        name: '',
        reqEducation: {
          educationLevel: '',
          qualification: '',
          diplomaSpeciality: '',
        },
        requiredSkills: {},
        skills: [],
      }
      this.professionSkills = JSON.parse(JSON.stringify(this.skills))
      this.updateSkills()
      this.$emit('close')
    },
    save(){
      this.$emit('close', this.profession)
      this.profession = {
        name: '',
        reqEducation: {
          educationLevel: '',
          qualification: '',
          diplomaSpeciality: '',
        },
        requiredSkills: {},
        skills: [],
      }
      this.professionSkills = JSON.parse(JSON.stringify(this.skills))
      this.updateSkills()
    },
    updateSkills() {
      let res = []
      this.professionSkills.forEach(function (item) {
        if (item.mark) {
          res.push({skill: {id: item.id, name: item.name}, mark: item.mark},)
        }
      })
      this.profession.requiredSkills = res
    }
  },
  props: {
    id: {
      default: '',
      type: String,
    },
    skills: {
      default() {
        return []
      },
      type: Array,
    },
    data: {
      default() {
        return {
          name: '',
          reqEducation: {
            educationLevel: '',
            qualification: '',
            diplomaSpeciality: '',
          },
          requiredSkills: {},
          skills: [],
        }
      },
      type: Object,
    },
  },
  data() {
    return {
      profession: this?.data,
      educationLevel: '',
      professionSkills: JSON.parse(JSON.stringify(this?.data?.skills?.length ? this?.data?.skills : this?.skills)),
      EducationLevels: {
        HIGHER: "Высшее",
        MIDSPEC: "Средне-специальное",
        SCHOOL: "11 классов",
        NO_REQ: "Требования не предъявляются"
      },
      marks: {
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
      },
    }
  },
  async beforeMount() {
  },
}
</script>
