<template>
    <div v-cloak>
        <transition name="fade" appear appear-active-class="fade-enter-active">
            <div class="wrap">


                <router-link class="goBack" to="/directories">
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5575 8.435L2.1225 5L5.5575 1.5575L4.5 0.5L-4.76837e-06 5L4.5 9.5L5.5575 8.435Z"
                              fill="#0033A0"/>
                    </svg>
                    назад
                </router-link>

                <h1>
                    <span>Справочник профессий<br>(специальностей)</span>
                    <div>

                        <v-dialog
                                v-model="dialogAdd"
                                persistent
                                max-width="600px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn rounded v-bind="attrs" v-on="on">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM8 14H10V10H14V8H10V4H8V8H4V10H8V14Z"
                                              fill="#69B3E7"/>
                                    </svg>
                                </v-btn>
                            </template>
                            <AddProfDialog :skills="skills" @close="saveProfession($event)"/>
                        </v-dialog>


                        <v-dialog
                                v-if="this.id"
                                v-model="dialogEdit"
                                persistent
                                max-width="600px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn rounded v-bind="attrs" v-on="on">
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.998779 14.25V18H4.74878L15.8088 6.94L12.0588 3.19L0.998779 14.25ZM3.91878 16H2.99878V15.08L12.0588 6.02L12.9788 6.94L3.91878 16ZM18.7088 2.63L16.3688 0.29C16.1688 0.09 15.9188 0 15.6588 0C15.3988 0 15.1488 0.1 14.9588 0.29L13.1288 2.12L16.8788 5.87L18.7088 4.04C19.0988 3.65 19.0988 3.02 18.7088 2.63Z"
                                              fill="#69B3E7"/>
                                    </svg>
                                </v-btn>
                            </template>
                            <AddProfDialog :id="id" :skills="skills" :data="data"
                                           @close="saveProfession($event)"/>
                        </v-dialog>

                        <v-btn v-if="this.id" rounded @click="deleteProfession">
                            <svg width="14" height="18" viewBox="0 0 14 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
                                      fill="#69B3E7"/>
                            </svg>
                        </v-btn>

                        <v-btn v-if="this.lastId" rounded @click="undoProfession">
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 4V0L3 5L8 10V6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18C4.69 18 2 15.31 2 12H0C0 16.42 3.58 20 8 20C12.42 20 16 16.42 16 12C16 7.58 12.42 4 8 4Z"
                                      fill="#69B3E7"/>
                            </svg>
                        </v-btn>
                    </div>
                </h1>


                <table class="table">
                    <thead>
                    <tr>
                        <th>Номер</th>
                        <th>Наименование профессии</th>
                        <th>Уровень образования</th>
                        <th>Квалификация</th>
                        <th>Специальность по диплому</th>
                        <th>Компетенции</th>
                        <th>Уровень развития</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(profession, professionIndex) in professions"
                        :key="professionIndex"
                        @click="id = profession['id'], data = profession, skills = skills"
                        :class="profession['id'] === id ? 'active':''"
                    >
                        <td>{{professionIndex + 1}}</td>
                        <td>{{profession.name}}</td>
                        <td>
                            <div v-if="profession.reqEducation">
                                {{EducationLevels[profession.reqEducation.educationLevel]}}
                            </div>
                        </td>
                        <td>
                            <div v-if="profession.reqEducation">{{profession.reqEducation.qualification}}</div>
                        </td>
                        <td>
                            <div v-if="profession.reqEducation">{{profession.reqEducation.diplomaSpeciality}}</div>
                        </td>
                        <td>
                            <div v-if="profession.requiredSkills">
                                <div v-for="(skill, skillIndex) in profession.requiredSkills" :key="skillIndex">
                                    {{skill.skill.name}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div v-if="profession.requiredSkills">
                                <div v-for="(skill, skillIndex) in profession.requiredSkills" :key="skillIndex">
                                    {{skill.mark}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>

<script>

    import api from "@/modules/api";
    import AddProfDialog from '../components/dialogs/AddProfDialog';

    export default {
        name: 'Directories',
        components: {AddProfDialog},

        data() {
            return {
                dialogAdd: false,
                dialogEdit: false,
                id: 0,
                data: {},
                skills: [],
                professions: [],
                lastOperation: '',
                lastId: '',
                lastValue: {},
                EducationLevels: {
                    HIGHER: "Высшее",
                    MIDSPEC: "Средне-специальное",
                    SCHOOL: "11 классов",
                    NO_REQ: "Требования не предъявляются"
                },
                EducationLevelCode: {
                    "Высшее": "HIGHER",
                    "Средне-специальное": "MIDSPEC",
                    "11 классов": "SCHOOL",
                    "Требования не предъявляются": "NO_REQ",
                },
            }
        },
        methods: {
            async setLastOperation(operation, req) {
                console.log(req)
                await this.getAllProfessions()
                this.id = 0
                this.data = {}
                this.lastOperation = operation
                this.lastId = req.id
                this.lastValue = req.name
                console.log('setLastOperation', this.id, this.lastId, this.lastOperation, this.lastValue)
            },
            async getAllProfessions() {
                const EducationLevels = this.EducationLevels
                const skillList = JSON.parse(JSON.stringify(this.skills))
                const req = await api.get("/professions?size=10000")
                if (req.ok) {
                    this.professions = req.payload._embedded.professions
                    if (this.professions.length) {
                        this.professions.forEach(function (profession) {
                            if (profession.reqEducation.educationLevel) {
                                profession.educationLevel = EducationLevels[profession.reqEducation.educationLevel]
                            }

                            let addSkills = []
                            let profSkills = JSON.parse(JSON.stringify(skillList))
                            if (profession.requiredSkills) {
                                profession.requiredSkills.forEach(function (skill) {
                                    let found = false
                                    Object.keys(profSkills).forEach(key => {
                                        if (skill.skill.id === profSkills[key].id) {
                                            profSkills[key].mark = skill.mark.toString()
                                            found = true
                                        }
                                    })
                                    if (!found) {
                                        addSkills.push({
                                            id: skill.skill.id,
                                            name: skill.skill.name,
                                            mark: skill.mark.toString(),
                                        })
                                    }
                                })
                            }
                            profession.skills = [...profSkills, ...addSkills]
                            profession.skills.sort((a, b) => {
                                if (a.name > b.name) {
                                    return 1
                                }
                                if (a.name === b.name) {
                                    return 0
                                }
                                if (a.name < b.name) {
                                    return -1
                                }
                            })
                            return
                        })
                    }
                    return
                }
                alert('Ошибка при обращении к серверу!')
            },
            async undoProfession() {
                console.log('undoProfession', this.id, this.lastId, this.lastOperation, this.lastValue)
                if (!this.lastId) {
                    return
                }
                this.id = this.lastId
                const profession = {
                    name: this.lastValue
                }
                switch (this.lastOperation) {
                    case 'create':
                        await this.deleteProfession()
                        break
                    case 'update':
                        await this.updateProfession(profession)
                        break
                    case 'delete':
                        await this.createProfession(profession)
                        break

                }
                this.lastOperation = ''
                this.lastId = 0
                this.lastValue = {}
            },
            async getProfessionById(id) {
                console.log('read', id)
                const req = await api.get("/professions/" + id)
                if (req.ok) {
                    return req.payload
                }
                alert('Ошибка при обращении к серверу!')
                return null
            },
            async createProfession(profession) {
                console.log('create', profession)
                const req = await api.postJson("/professions/", profession)
                if (req.ok) {
                    await this.setLastOperation('create', req.payload)
                    return true
                }
                alert('Ошибка при обращении к серверу!')
                return false
            },
            async updateProfession(profession) {
                console.log('update', this.id, profession)
                const prev = await this.getProfessionById(this.id)
                const req = await api.putJson("/professions/" + this.id, profession)
                if (req.ok) {
                    await this.setLastOperation('update', prev)
                    return true
                }
                alert('Ошибка при обращении к серверу!')
                return false
            },
            async deleteProfession() {
                console.log('delete', this.id)
                const prev = await this.getProfessionById(this.id)
                const req = await api.del("/professions/" + this.id)
                if (req.ok) {
                    await this.setLastOperation('delete', prev)
                    return true
                }
                alert('Ошибка при обращении к серверу!')
                return false
            },
            async saveProfession(data) {
                this.dialogEdit = false
                this.dialogAdd = false
                if (!data) {
                    this.id = 0
                    this.data = {}
                    return
                }
                if (data.educationLevel) {
                    data.reqEducation.educationLevel = this.EducationLevelCode[data.educationLevel]
                }
                console.log('saveProfession', data)
                if (this.id) {
                    this.updateProfession(data)
                    return
                }
                this.createProfession(data)
            },
            async getAllSkills() {
                const req = await api.get("/skills?size=10000")
                if (req.ok) {
                    console.log(req.payload)
                    return req.payload._embedded.skills
                }
                alert('Ошибка при обращении к серверу!')
            },
        },
        async beforeMount() {
            const skills = await this.getAllSkills()
            if (skills) {
                this.skills = skills.map(function (skill) {
                    return {
                        id: skill.id,
                        name: skill.name,
                        mark: 0,
                    }
                })
                this.skills.sort((a, b) => {
                    if (a.name > b.name) {
                        return 1
                    }
                    if (a.name === b.name) {
                        return 0
                    }
                    if (a.name < b.name) {
                        return -1
                    }
                })
            }
            await this.getAllProfessions()
            /*
                        if (id) {
                            profession = data
                            if (profession) {
                                if (profession.reqEducation.educationLevel) {
                                    profession.educationLevel = this.EducationLevels[profession.reqEducation.educationLevel]
                                }
                                console.log('educationLevel', profession.reqEducation.reqEducation, profession.educationLevel)
                                this.profession = profession
                            }
                            if (profession.requiredSkills) {
                                this.professionSkills.forEach(function (item) {
                                    // let found = false
                                    profession.requiredSkills.forEach(function (skill) {
                                        if (skill.skill.id === item.id) {
                                            item.mark = skill.mark.toString()
                                            // found = true
                                            // break
                                        }
                                    })
                                    // if(!found) {
                                    //     res.push({skill: {id: item.id, name: item.name}, mark: item.mark},)
                                    // }
                                })
                            }
                        }
            */

        },

    }

</script>

<style lang="scss">
    @import "../styles/main.scss";
</style>


<style scoped lang="scss">
</style>
